import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Container from '../primitives/grid/container'
import Row from '../primitives/grid/row'
import StyledLink from '../primitives/styled-link'
import { GatsbyImageSVGFallback } from '../primitives/image/gatsby-image-svg-fallback'
import FooterLogo from '../../images/footer-logo.svg'
import Accordion, {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
} from '../primitives/accordion'

interface FooterFields {
    wp: {
        footerOptions: {
            footer: {
                heading?: string
                contactColumns: {
                    heading?: string
                    phoneNumber?: string
                    text?: string
                    linkText?: string
                    linkUrl?: string
                }[]
                menueColumns: {
                    heading?: string
                    items: {
                        linkText?: string
                        linkUrl?: string
                    }[]
                }[]
                crossLinks: {
                    heading?: string
                    links: {
                        linkText?: string
                        linkUrl?: string
                    }[]
                }
                socialMedia: {
                    logo: {
                        altText: string
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData
                            }
                            publicURL: string
                        }
                    }
                    linkUrl: string
                }[]
            }
        }
    }
}

const DesktopFooter = () => {
    const {
        wp: {
            footerOptions: { footer },
        },
    } = useStaticQuery<FooterFields>(graphql`
        {
            wp {
                footerOptions {
                    footer {
                        heading
                        contactColumns {
                            heading
                            phoneNumber
                            text
                            linkText
                            linkUrl
                        }
                        menueColumns {
                            heading
                            items {
                                linkText
                                linkUrl
                            }
                        }
                        crossLinks {
                            heading
                            links {
                                linkText
                                linkUrl
                            }
                        }
                        socialMedia {
                            logo {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 100)
                                    }
                                    publicURL
                                }
                            }
                            linkUrl
                        }
                    }
                }
            }
        }
    `)
    return (
        <div
            css={{
                width: '100%',
                display: 'flex',
                position: 'relative',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
        >
            {(footer?.contactColumns?.length > 0 ||
                footer?.socialMedia?.length > 0) && (
                <div
                    css={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'var(--color-blue-dunkelblau)',
                    }}
                >
                    <Container
                        css={{
                            paddingTop: '76px',
                            paddingBottom: '76px',
                            '@media screen and (max-width: 1200px)': {
                                paddingTop: '41px',
                            },
                        }}
                    >
                        <Row>
                            <h2
                                className="h1"
                                css={{
                                    color: 'var(--color-gray-almostwhite)',
                                    fontSize: '40px',
                                    gridColumn: 'span 12',
                                    paddingBottom: '60px',
                                    '@media screen and (max-width: 1200px)': {
                                        paddingBottom: '35px',
                                        fontSize: '30px',
                                    },
                                }}
                            >
                                {footer.heading}
                            </h2>
                            {footer?.contactColumns?.length > 0 &&
                                footer.contactColumns.map(
                                    (
                                        {
                                            heading,
                                            phoneNumber,
                                            text,
                                            linkText,
                                            linkUrl,
                                        },
                                        index
                                    ) => (
                                        <div
                                            key={index}
                                            css={{
                                                width: '90%',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                paddingBottom: '50px',
                                                gridColumn: 'span 4',
                                                '@media screen and (max-width: 1200px)': {
                                                    gridColumn: 'span 12',
                                                },
                                                '@media screen and (max-width: 767px)': {
                                                    display: 'none',
                                                },
                                            }}
                                        >
                                            <h3
                                                className="h2"
                                                css={{
                                                    color:
                                                        'var(--color-gray-almostwhite)',
                                                    '@media screen and (max-width: 1200px)': {
                                                        fontSize:
                                                            '14px !important',
                                                    },
                                                }}
                                            >
                                                {heading}
                                            </h3>
                                            {phoneNumber && (
                                                <span
                                                    className="h2"
                                                    css={{
                                                        color:
                                                            'var(--color-gray-almostwhite)',
                                                        paddingTop: '20px',
                                                        '@media screen and (max-width: 1200px)': {
                                                            fontSize:
                                                                '26px !important',
                                                            paddingTop: '5px',
                                                        },
                                                    }}
                                                >
                                                    {phoneNumber}
                                                </span>
                                            )}
                                            <span
                                                className="p2"
                                                css={{
                                                    color:
                                                        'var(--color-gray-almostwhite)',
                                                    paddingTop: '32px',
                                                    paddingBottom: '8px',
                                                    '@media screen and (max-width: 1200px)': {
                                                        fontSize:
                                                            '14px !important',
                                                        paddingTop: '10px',
                                                        paddingBottom: '5px',
                                                    },
                                                }}
                                            >
                                                {text}
                                            </span>
                                            {linkUrl && linkText && (
                                                <StyledLink
                                                    to={linkUrl}
                                                    className="p2"
                                                    small
                                                    variant="underlined"
                                                    css={{
                                                        color:
                                                            'var(--color-gray-almostwhite)',
                                                        '@media screen and (max-width: 1200px)': {
                                                            fontSize:
                                                                '14px !important',
                                                        },
                                                    }}
                                                >
                                                    {linkText}
                                                </StyledLink>
                                            )}
                                        </div>
                                    )
                                )}
                            {footer?.socialMedia?.length > 0 &&
                                footer?.socialMedia[0].logo !== null && (
                                    <div
                                        css={{
                                            width: '100%',
                                            display: 'flex',
                                            marginTop: '80px',
                                            alignItems: 'center',
                                            gridColumn: 'span 12',
                                            paddingTop: '35px',
                                            borderColor:
                                                'var(--color-gray-almostwhite)',
                                            justifyContent: 'space-between',
                                            borderWidth: '1px 0px 0px',
                                            '@media screen and (max-width: 1200px)': {
                                                marginTop: '20px',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                            },
                                        }}
                                    >
                                        <span
                                            className="p2"
                                            css={{
                                                color:
                                                    'var(--color-gray-almostwhite)',
                                                '@media screen and (max-width: 1200px)': {
                                                    fontSize: '14px !important',
                                                },
                                            }}
                                        >
                                            Besuchen Sie uns auf unseren
                                            sozialen Netzwerken
                                        </span>
                                        <div
                                            css={{
                                                width: 'auto',
                                                height: 'auto',
                                                display: 'flex',
                                            }}
                                        >
                                            {footer?.socialMedia.map(
                                                ({ logo, linkUrl }, index) => (
                                                    <a
                                                        href={linkUrl}
                                                        key={index}
                                                    >
                                                        <GatsbyImageSVGFallback
                                                            alt={logo.altText}
                                                            src={logo}
                                                            css={{
                                                                objectFit:
                                                                    'cover',
                                                                marginLeft:
                                                                    '25px',
                                                                height: '25px',
                                                                '@media screen and (max-width: 1200px)': {
                                                                    marginTop:
                                                                        '20px',
                                                                    marginLeft:
                                                                        '0',
                                                                    marginRight:
                                                                        '20px',
                                                                },
                                                            }}
                                                        />
                                                    </a>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                        </Row>
                    </Container>
                </div>
            )}
            <div
                css={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'var(--color-blue-dunkelblau-dark)',
                }}
            >
                <Container
                    css={{
                        paddingTop: '76px',
                        paddingBottom: '76px',
                        '@media screen and (max-width: 1200px)': {
                            paddingTop: '41px',
                        },
                    }}
                >
                    <Row>
                        {!(
                            footer?.contactColumns?.length > 0 ||
                            footer?.socialMedia?.length > 0
                        ) && (
                            <h3
                                className="h1"
                                css={{
                                    color: 'var(--color-gray-almostwhite)',
                                    fontSize: '40px',
                                    gridColumn: 'span 12',
                                    paddingBottom: '60px',
                                    '@media screen and (max-width: 1200px)': {
                                        paddingBottom: '35px',
                                        fontSize: '30px',
                                    },
                                }}
                            >
                                {footer.heading}
                            </h3>
                        )}

                        <Accordion
                            css={{
                                gridColumn: 'span 12',
                                borderTop:
                                    '1px solid var(--color-gray-almostwhiter)',
                                '@media screen and (min-width: 1201px)': {
                                    display: 'none',
                                },
                            }}
                        >
                            {footer?.menueColumns &&
                                footer?.menueColumns.map(
                                    ({ heading, items }, index) => (
                                        <AccordionItem
                                            key={index}
                                            css={{
                                                width: '100%',
                                                display: 'flex',
                                                maxWidth: '244px',
                                                alignItems: 'flex-start',
                                                gridColumn: 'span 3',
                                                flexDirection: 'column',
                                                "&[data-state='open']": {
                                                    paddingBottom: '20px',
                                                    '.AccordionIcon': {
                                                        transform:
                                                            'rotate(180deg)',
                                                    },
                                                },
                                                marginTop: '17px',
                                                borderBottom:
                                                    '1px solid var(--color-gray-almostwhiter)',
                                                '@media screen and (max-width: 1200px)': {
                                                    gridColumn: 'span 12',
                                                    maxWidth: '100%',
                                                },
                                            }}
                                        >
                                            <AccordionButton
                                                css={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingBottom: '17px',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <span
                                                    className="h5"
                                                    css={{
                                                        color:
                                                            'var(--color-gray-hellgrau)',
                                                        fontWeight: 600,
                                                        '@media screen and (max-width: 1200px)': {
                                                            fontSize:
                                                                '16px !important',
                                                        },
                                                    }}
                                                >
                                                    {heading}
                                                </span>
                                                <AccordionIcon color="var(--color-gray-hellgrau)" />
                                            </AccordionButton>
                                            <AccordionPanel
                                                css={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    paddingLeft: '25px',
                                                }}
                                            >
                                                {items.map(
                                                    (
                                                        { linkText, linkUrl },
                                                        index
                                                    ) => (
                                                        <span
                                                            key={index}
                                                            className="p2"
                                                            css={{
                                                                color:
                                                                    'var(--color-gray-almostwhite)',
                                                                marginBottom:
                                                                    '10px',
                                                                '@media screen and (max-width: 1200px)': {
                                                                    fontSize:
                                                                        '14px !important',
                                                                },
                                                            }}
                                                        >
                                                            <Link
                                                                to={
                                                                    linkUrl ??
                                                                    '#'
                                                                }
                                                            >
                                                                {linkText}
                                                            </Link>
                                                        </span>
                                                    )
                                                )}
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )
                                )}
                        </Accordion>
                        {footer?.menueColumns &&
                            footer.menueColumns.map(
                                ({ heading, items }, index) => (
                                    <div
                                        key={index}
                                        css={{
                                            width: '100%',
                                            display: 'flex',
                                            maxWidth:
                                                footer?.menueColumns.length > 1
                                                    ? '244px'
                                                    : '500px',
                                            alignItems: 'flex-start',
                                            gridColumn: 'span 3',
                                            flexDirection: 'column',
                                            '@media screen and (max-width: 1200px)': {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        <span
                                            className="h2"
                                            css={{
                                                color:
                                                    'var(--color-gray-almostwhite)',
                                                fontSize: '25px',
                                                paddingBottom: '18px',
                                            }}
                                        >
                                            {heading}
                                        </span>
                                        {items.map(
                                            ({ linkText, linkUrl }, index) => (
                                                <span
                                                    key={index}
                                                    className="p2"
                                                    css={{
                                                        color:
                                                            'var(--color-gray-almostwhite)',
                                                        marginTop: '7px',
                                                    }}
                                                >
                                                    <StyledLink
                                                        small
                                                        to={linkUrl ?? '#'}
                                                    >
                                                        {linkText}
                                                    </StyledLink>
                                                </span>
                                            )
                                        )}
                                    </div>
                                )
                            )}
                        <div
                            css={{
                                width: '100%',
                                display: 'flex',
                                marginTop: '80px',
                                alignItems: 'center',
                                gridColumn: 'span 12',
                                paddingTop: '35px',
                                borderColor: 'var(--color-gray-almostwhite)',
                                justifyContent: 'space-between',
                                borderWidth: '1px 0px 0px',
                                '@media screen and (max-width: 1200px)': {
                                    borderWidth: '0',
                                    marginTop: '20px',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                },
                            }}
                        >
                            <div
                                css={{
                                    width: 'auto',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <h3
                                    className="h2"
                                    css={{
                                        color: 'var(--color-gray-almostwhite)',
                                        fontSize: '25px',
                                        paddingBottom: '8px',
                                        '@media screen and (max-width: 1200px)': {
                                            fontSize: '14px !important',
                                        },
                                    }}
                                >
                                    {footer.crossLinks.heading}
                                </h3>
                                {footer?.crossLinks?.links &&
                                    footer.crossLinks.links.map(
                                        ({ linkText, linkUrl }, index) => (
                                            <span
                                                key={index}
                                                className="p2"
                                                css={{
                                                    color:
                                                        'var(--color-gray-almostwhite)',
                                                    marginTop: '7px',
                                                    '@media screen and (max-width: 1200px)': {
                                                        fontSize:
                                                            '14px !important',
                                                    },
                                                }}
                                            >
                                                <StyledLink
                                                    small
                                                    to={linkUrl ?? '#'}
                                                >
                                                    {linkText}
                                                </StyledLink>
                                            </span>
                                        )
                                    )}
                            </div>
                            <div
                                css={{
                                    width: 'auto',
                                    height: 'auto',
                                    display: 'flex',
                                    '@media screen and (max-width: 1200px)': {
                                        marginTop: '30px',
                                    },
                                }}
                            >
                                <a
                                    href="https://www.drk.de"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FooterLogo
                                        css={{
                                            width: '121.41px',
                                            height: 'auto',
                                        }}
                                    />
                                </a>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default DesktopFooter
